<template>
    <headerComp :mode="'contact'"></headerComp>
      <div class="d-flex container-full">
        <div class="col-12">
            <div class="d-flex justify-content-center fade-in2">
                <div class="logo-contact">Contact</div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="description-contact">
                david.takahashi.atelier485@gmail.com
              </div>
            </div>
        </div>
      </div>
  </template>
  
  <script>
  import HeaderComp from '../components/HeaderComp.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'contact',
    components: {
         HeaderComp
       },
    data () {
    },
    methods: {
    }
  };
  </script>