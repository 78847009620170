<template>
    <headerComp :mode="'labo'"></headerComp>
    <div class="container-full">
      <div class="col-12 d-flex justify-content-center">
        <div class="col-12 d-flex justify-content-start logo-labo"  style="width: 70vw">
          Affichage d'images prises avec une caméra à 360 degrés
        </div>
      </div>
      <div class="col-12">
          <div class="d-flex justify-content-center">
            <div>
                <v-pannellum :src="data" :hfov="100" :showZoom="true" style="width: 70vw; height: 70vh;">
                  <!--
                  <div id="controls">
                      <div class="ctrl" id="pan-up" :onClick="onClickPanUp">&#9650;</div>
                      <div class="ctrl" id="pan-down" :onClick="onClickPanDown">&#9660;</div>
                      <div class="ctrl" id="pan-left" :onClick="onClickPanLeft">&#9664;</div>
                      <div class="ctrl" id="pan-right" :onClick="onClickPanRight">&#9654;</div>
                      <div class="ctrl" id="zoom-in" :onClick="onClickZoomIn">&plus;</div>
                      <div class="ctrl" id="zoom-out" :onClick="onClickZoomOut">&minus;</div>
                  </div>
                  -->
                </v-pannellum>
            </div>
          </div>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <div class="col-12 d-flex justify-content-end description-labo" style="width: 70vw">
          Exposition chez CryBaby Paris, novembre 2023
        </div> 
      </div>
    </div>
    
</template>

<script>
import HeaderComp from '../components/HeaderComp.vue';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'about',
  components: {
    HeaderComp
  },
  data () {
    return {
        //data : "/img/labo/labo01/R0010022.JPG"
        data: {
          default: {
    firstScene: 'cube',
  },
  scenes: {
    cube: {
      hfov: 110,
      pitch: -3,
      yaw: 117,
      type: 'equirectangular',
      panorama: "/img/labo/labo01/R0010022.JPG",
      hotSpots: [
        {
            "pitch": 18,
            "yaw": 345,
            "type": "info",
            "text": "Peinture",
            "URL": "/art"
        },
        
        {
            "pitch": 18,
            "yaw": 73,
            "type": "info",
            "text": "Affiche",
            "URL": "/design"
        },
        {
            "pitch": 16,
            "yaw": 180,
            "type": "info",
            "text": "les disques vinyles de CryBaby"
        }
          

      ],
    },

  },
        }        
      }
  },
  created: function() {
  },
  methods: {
    onClickPanUp(){
    }
  }
};
</script>