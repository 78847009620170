<style>
.labo02-parent-container{
    padding:2em;
    height:100%;
}
.labo02-container {
        width: 35vw;
        height: 35vw;
        overflow-x: scroll;
        overflow-y: hidden;
        perspective: 20em;
        /*perspective-origin: center center;*/
      }
.bg {
        width: 1000px;
        height: 1000px;
        background-color: lightgray;
      }
      .hurdle {
        /*transform: rotateY(90deg);*/
        transform: perspective(200px) rotateY(60deg);
        transform-origin: center left;
        background-color: red;
        width: 25vw;
        height: 25vw;
      }
      .hurdle-select{
        /*transform: perspective(300px) rotateY(30deg);*/
      }
</style>
<template>
    <headerComp :mode="'labo'"></headerComp>
    <div class="container-full">
        <div class="labo02-parent-container d-flex justify-content-center align-items-center">
        <div class="labo02-container">
            <div class="bg"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 50px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 100px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 150px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 200px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 250px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 300px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 350px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 400px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 450px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 500px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 550px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 600px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 650px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 700px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 750px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 800px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 850px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 900px;"></div>
            <div class="hurdle" style="position: absolute; top: 50px; left: 950px;"></div>
            <!--
            <div class="hurdle" style="position: absolute; top: 100px; left: 0;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 10px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 20px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 30px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 40px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 50px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 60px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 70px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 80px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 90px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 100px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 110px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 120px;"></div>
            <div class="hurdle" style="position: absolute; top: 100px; left: 130px;"></div>
        -->
        </div>
        </div>
    </div>
</template>

<script>
import HeaderComp from '../components/HeaderComp.vue';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'about',
  components: {
    HeaderComp
  },
  data () {
    return {
        //data : "/img/labo/labo01/R0010022.JPG"
        data: {
          default: {
    firstScene: 'cube',
  },
  scenes: {
    cube: {
      hfov: 110,
      pitch: -3,
      yaw: 117,
      type: 'equirectangular',
      panorama: "/img/labo/labo01/R0010022.JPG",
      hotSpots: [
        {
            "pitch": 18,
            "yaw": 345,
            "type": "info",
            "text": "Peinture",
            "URL": "/art"
        },
        
        {
            "pitch": 18,
            "yaw": 73,
            "type": "info",
            "text": "Affiche",
            "URL": "/design"
        },
        {
            "pitch": 16,
            "yaw": 180,
            "type": "info",
            "text": "les disques vinyles de CryBaby"
        }
          

      ],
    },

  },
        }        
      }
  },
  created: function() {
  },
  methods: {
    onClickPanUp(){
    }
  }
};
</script>