<template>
  <div class="accordion" v-cloak>
    <button class="accordion-trigger" type="button" :class="{ '_state-open': isOpened }" @click="accordionToggle()">
      <slot name="title"></slot>
    </button>
  <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
    <div class="accordion-target" :class="{ '_state-open': isOpened }" v-if="isOpened">
      <slot name="content"></slot>
    </div>
  </transition>
</div>
</template>

<script>
  import { defineComponent } from 'vue'
  export default defineComponent({
    data() {
      return {
        isOpened: false
      };
    },
    props: {
    
    },

    methods: {
      accordionToggle: function(){
        this.isOpened = !this.isOpened;
      },
      beforeEnter: function(el) {
        el.style.height = '0';
      },
      enter: function(el) {
        //console.log('enter');
        setTimeout(function(){el.style.height = el.scrollHeight + 'px';}, 300);
      },
      beforeLeave: function(el) {
        el.style.height = el.scrollHeight + 'px';
      },
      leave: function(el) {
        el.style.height = '0';
      }
    }
});
</script>