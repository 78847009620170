<template>
    <headerComp :mode="'design'"></headerComp>
      <div class="container-work">
        <accordionComp>
          <template v-slot:title>Site web pour le designer</template>
          <template v-slot:content>
            <div class="accordion-content">
              <div class="row">
                <div class="col-md-6">
                  <carouselComp :items="carouselItems01"></carouselComp>
                </div>
                <div class="d-flex justify-content-center align-items-center col">
                    Site web de Julie HERMANT.
                  </div>
                </div>
            </div>
          </template>
        </accordionComp>
        <accordionComp>
          <template v-slot:title>Guide touristique</template>
          <template v-slot:content>
            <div class="accordion-content">
              <div class="row">
                <div class="col-md-6">
                  <carouselComp :items="carouselItems02"></carouselComp>
                </div>
                <div class="d-flex justify-content-center align-items-center col">
                    A Literary tour of Katsushika<br>
                    Guide touristique de Katsushika.
                  </div>
                </div>
            </div>
          </template>
        </accordionComp>
        <accordionComp>
          <template v-slot:title>Catalogue pour artiste</template>
          <template v-slot:content>
            <div class="accordion-content">
              <div class="row">
                <div class="col-md-6">
                  <carouselComp :items="carouselItems03"></carouselComp>
                </div>
                <div class="d-flex justify-content-center align-items-center col">
                  485 Art Magazine<br/>
                  Catalogue d'œuvres de Yamila Maranon.
                </div>
              </div>
            </div>
          </template>
        </accordionComp>
        <accordionComp>
          <template v-slot:title>Catalogue pour le designer</template>
          <template v-slot:content>
            <div class="accordion-content">
              <div class="row">
                <div class="col-md-6">
                  <carouselComp :items="carouselItems04"></carouselComp>
                </div>
                <div class="d-flex justify-content-center align-items-center col">
                  Vivat Veritas<br/>
                  Catalogue de designer japonaise.
                </div>
              </div>
            </div>
          </template>
        </accordionComp>
        <accordionComp>
          <template v-slot:title>Affiches</template>
          <template v-slot:content>
            <div class="accordion-content">
              <div class="row">
                <div class="col-md-6">
                  <carouselComp :items="carouselItems05"></carouselComp>
                </div>
                <div class="d-flex justify-content-center align-items-center col">
                  Affiches pour exposition
                </div>
              </div>
            </div>
          </template>
        </accordionComp>
        <accordionComp>
          <template v-slot:title>Brochure pour événement artistique</template>
          <template v-slot:content>
            <div class="accordion-content">
              <div class="row">
                <div class="col-md-6">
                  <carouselComp :items="carouselItems06"></carouselComp>
                </div>
                <div class="d-flex justify-content-center align-items-center col">
                  Brochure pour Shibamata Artworld
                </div>
              </div>
            </div>
          </template>
        </accordionComp>
        <accordionComp>
          <template v-slot:title>Visuel pour design</template>
          <template v-slot:content>
            <div class="accordion-content">
              <div class="row">
                <div class="col-md-6">
                  <carouselComp :items="carouselItems07"></carouselComp>
                </div>
                <div class="d-flex justify-content-center align-items-center col">
                  Visuel pour COSA Record
                </div>
              </div>
            </div>
          </template>
        </accordionComp>
      </div>
  </template>
  
  <script>
  import HeaderComp from '../components/HeaderComp.vue';
  import AccordionComp from '../components/AccordionComp.vue';
  import CarouselComp from '../components/CarouselComp.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'design',
    components: {
      HeaderComp,
      AccordionComp,
      CarouselComp
    },
    data () {
      return {
          carouselItems01:[
            "/img/work01/work01_1.png",
            "/img/work01/work01_2.png",
            "/img/work01/work01_3.png",
            "/img/work01/work01_4.png",
            "/img/work01/work01_5.png"
          ],
          carouselItems02:[
            "/img/work02/work02_1.jpg",
            "/img/work02/work02_2.jpg",
            "/img/work02/work02_3.jpg",
            "/img/work02/work02_4.jpg",
            "/img/work02/work02_5.jpg",
            "/img/work02/work02_6.jpg",
            "/img/work02/work02_7.jpg",
            "/img/work02/work02_8.jpg",
            "/img/work02/work02_9.jpg"
          ],
          carouselItems03:[
            "/img/work03/work03_1.jpg",
            "/img/work03/work03_2.jpg",
            "/img/work03/work03_3.jpg",
            "/img/work03/work03_4.jpg",
            "/img/work03/work03_5.jpg",
            "/img/work03/work03_6.jpg"
          ],
          carouselItems04:[
            "/img/work04/work04_1.jpg",
            "/img/work04/work04_2.jpg",
            "/img/work04/work04_3.jpg",
            "/img/work04/work04_4.jpg",
            "/img/work04/work04_5.jpg"
          ],
          carouselItems05: [
            "/img/work05/work05_3.jpg",
            "/img/work05/work05_1.jpg",
            "/img/work05/work05_2.jpg",
          ],
          carouselItems06:[
            "/img/work06/work06_1.png",
            "/img/work06/work06_2.png"
          ],
          carouselItems07:[
            "/img/work07/work07_1.jpg",
          ]
        }
    },
    created: function() {
    },
    methods: {
      
    }
  };
  </script>