  <template>
    <headerComp :mode="'about'"></headerComp>
    <div class="d-flex container-full">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <div class="d-flex fade-in2">
            <div class="logo-about">A propose de ...</div>
          </div>
          <div class="d-flex fade-in3">
            <div class="description-about">
              David Takahashi est créateur de site web, graphiste et artiste plasticien basé à Marseille.<br />
              Depuis 2016 il expose regulierement au salon Playtime à Paris ainsi qu'au festival d'art Misaki Art World
              au Japon.<br />
              Depuis 2017 il expose chaque année à la galerie Atelier 485 Tokyo ainsi qu'à la galerie INTERART 7.<br />
              En 2021, 2022 et 2023 il fait une residence artistique au 59 Rivoli à Paris.<br />
              En 2023 il s'installe en France et vit à Marseille.
            </div>
          </div>
          <div class="d-flex fade-in2">
            <div class="logo-about">Connaissances informatiques</div>
          </div>
          <div class="d-flex fade-in3">
            <div class="description-about">
              <span class="logo-about2">OS:</span><br />Mac, Windows, Linux<br />
              <br />
              <span class="logo-about2">Design graphique:</span><br />Adobe(Illustrator, Photoshop, inDesign), GIMP,
              Blender<br />
              <br />
              <span class="logo-about2">Langages de programmation:</span><br />HTML/CSS, Bootstrap, Javascript(JQuery,
              React, Vue.js, OpenLayers, Leaflet), Typescript, PHP(CakePHP), JAVA, C#, Swift, Python,
              SQL<br /><br /><br />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="about-spacer"></div>
        </div>
      </div>
      <div class="row"></div>
    </div>
  </template>
  
  <script>
  import HeaderComp from '../components/HeaderComp.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'about',
    components: {
      HeaderComp
    },
    data () {
      return {
        }
    },
    created: function() {
    },
    methods: {
    }
  };
  </script>