import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import DesignView from '../views/Design.vue'
import ArtWorkView from '../views/ArtWork.vue'
import ArtWorkView01 from '../views/ArtWork01.vue'
import ArtWorkView02 from '../views/ArtWork02.vue'
import LaboListView from '../views/LaboList.vue'
import LaboView01 from '../views/Labo01.vue'
import LaboView02 from '../views/Labo02.vue'
import AboutView from '../views/About.vue'
import ContactView from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/design',
    name: 'design',
    component: DesignView
  },
  {
    path: '/art',
    name: 'art',
    component: ArtWorkView
  },
  {
    path: '/art01',
    name: 'art01',
    component: ArtWorkView01
  },
  {
    path: '/art02',
    name: 'art02',
    component: ArtWorkView02
  },
  {
    path: '/labo',
    name: 'labo',
    component: LaboListView
  },
  {
    path: '/labo01',
    name: 'labo01',
    component: LaboView01
  },
  {
    path: '/labo02',
    name: 'labo02',
    component: LaboView02
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
