<template>
  <headerComp :mode="'home'"></headerComp>
  <div class="d-flex container-full row justify-content-center">
    <div class="col-md-9 col-sm-7 col-xs-7 home-right-spacer">
      <div class="row">
        <div class="col-md-7 home-img-container d-flex align-items-center justify-content-center">
        </div>
        <div class="col-md-5 home-description  d-flex align-items-center justify-content-center">
          <div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-5 col-sm-7 d-flex justify-content-center">
      <div class="d-flex logo-container-parent justify-content-center fade-in2">
        <div class="logo-container">
          <div class="logo1">David</div>
          <div class="logo2">TAKAHASHI</div>
          <div class="logo3">Creations for design and art</div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import HeaderComp from '../components/HeaderComp.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'home',
    components: {
      HeaderComp
    },
    data () {
      return {
        }
    },
    created: function() {
    },
    methods: {
    }
  };
  </script>