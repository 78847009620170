<template>
    <headerComp :mode="'labo'"></headerComp>
    <div class="container-full">
      <div class="row">
        <div class="logo-labo-container fade-in2">
            <div class="logo-labo-list">
                Labo
            </div>
            <div class="description-labo-list">
                Les pages pour expérimenter les techniques de sites Web
            </div>
        </div>
      </div>
      <div class="labo-list-container fade-in">
        <div class="row">
        <div class="col-md-5 d-flex labo-list"></div>
        <div class="col-md-7 d-flex labo-list">
            <router-link to="/labo01">Affichage d'images prises avec une caméra à 360 degrés</router-link>
        </div>
         </div>
      </div>  
    </div>
  </template>
  
  <script>
    import HeaderComp from '../components/HeaderComp.vue';
    export default{
      components: {
        HeaderComp
      },
      data() {
        return {
        }
      },
      created: function() {
      },
      methods: {
      }
    };
  </script>