import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
//import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/common.css'
import './assets/accordion.css'
import VuePannellum from 'vue-pannellum'

loadFonts()

const app = createApp(App)

/*
app.config.globalProperties.$axios = axios.create(
    {
      //baseURL: 'http://localhost',
      headers: {
        "Content-type": "application/json",
        //'X-Requested-With': 'XMLHttpRequest',
        //'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }
    )
*/

app.component('VPannellum', VuePannellum)


app.use(router)
  //.use(store)
  .use(vuetify)
  .mount('#app')
