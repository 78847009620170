<template>
    <div class="header-container">
        <div class="d-flex">
            <nav class="d-flex nav-container">
              <div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'home'}">
                <router-link to="/">Home</router-link>
                </div>
              </div>
              <div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'design'}">
                <router-link to="/design">Design</router-link>
                </div>
              </div>
              <div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'art'}">
                <router-link to="/art">Art</router-link>
                </div>
              </div>
              <div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'labo'}">
                <router-link to="/labo">Labo</router-link>
                </div>
              </div>
              <div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'about'}">
                <router-link to="/about">About</router-link>
                </div>
              </div>
              <div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'contact'}">
                <router-link to="/contact">Contact</router-link>
                </div>
              </div>
            </nav>
        </div>
    </div>
    <div class="header-container-sp">
      <div className="col d-flex justify-content-end">
        <v-menu>
            <template v-slot:activator="{ props }">
              <v-app-bar-nav-icon variant="text" v-bind="props" style="font-size: 1.5em;"></v-app-bar-nav-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menus"
                :key="index"
                :value="index"
              >                
                <v-list-item-title @click="onClickMenu(index)">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
    </div>
</template>

<script>
  import { ref, defineComponent } from 'vue'
  export default defineComponent({
    props: {
        mode:{
            type:String
        }
       
    },
    setup(props) {
        const currentMode = ref(props.mode);
        return {
            currentMode
        }
    },
    data(){
      return{
        menus: [
          { 
            title: 'Home',
            props: {
              prependIcon: 'mdi-email-multiple',
            }
            ,url:'/'
          },
          {
            title: 'Design',
            props: {
              prependIcon: 'mdi-account-multiple',
            }
            ,url:'/design'
          },
          {
            title: 'Art',
            props: {
              prependIcon: 'mdi-account-multiple',
            }
            ,url:'/art'
          },
          {
            title: 'Labo',
            props: {
              prependIcon: 'mdi-account-multiple',
            }
            ,url:'/labo'
          },
          {
            title: 'About',
            props: {
              prependIcon: 'mdi-account-multiple',
            }
            ,url:'/about'
          },
          {
            title: 'Contact',
            props: {
              prependIcon: 'mdi-account-multiple',
            }
            ,url:'/contact'
          }
        ],        
      }
    },
    methods :{
        onClickMenu(index){
            let url = this.menus[index].url;
            this.$router.push(url);
        }
    }
  })
  </script>