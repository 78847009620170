<template>
    <headerComp :mode="'art'"></headerComp>
    <div class="container-full">
        <div class="row">
            <div class="d-flex logo-art-container fade-in2">
                <div class="logo-art">
                    Série Bleu
                </div>
            </div>
        </div>
        <div class="art-container fade-in">
            <div class="row" v-for="(row, key) in rowCount" :key="key">
                <div class="col-md-3" v-for="(collection, key2) in itemCountInRow(row)" :key="key2">
                    <div v-if="collection.url" class="art-img-col" @click="showDetail(collection.url, collection.description)">
                        <!-- FileName -->
                        <img class="img-art" :src="collection.url" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center">
        <v-dialog v-model="openArtDlg" persistent width="100%" height="100%">
            <artDialog :fileUrl="currentArtUrl" :description="currentDescription" @eventEmit="execOpenArtDlg">
            </artDialog>
        </v-dialog>
    </div>
</template>

<script>
import HeaderComp from '../components/HeaderComp.vue';
import ArtDialog from '../components/ArtDialog.vue';
export default {
    components: {
        HeaderComp,
        ArtDialog
    },
    data() {
        return {
            datas: [
                { url: "/img/art/workb01.jpg", description: "Acrylique sur papier\n70cm x 50cm" },
                { url: "/img/art/workb02.jpg", description: "Acrylique sur papier\n50cm x 70cm" },
                { url: "/img/art/workb03.jpg", description: "Acrylique sur papier\n35cm x 50cm" },
                { url: "/img/art/workb04.jpg", description: "Acrylique sur papier\n70cm x 50cm" },
                { url: "/img/art/workb05.jpg", description: "Acrylique sur papier\n65cm x 50cm" },
                { url: "/img/art/workb06.jpg", description: "Acrylique sur papier\n70cm x 50cm" },
                { url: "/img/art/workb07.jpg", description: "Acrylique sur papier\n70cm x 50cm" },
                { url: "/img/art/workb08.jpg", description: "Acrylique sur papier\n50cm x 35cm" },
                { url: "/img/art/workb09.jpg", description: "Acrylique sur papier\n70cm x 50cm" },
                { url: "/img/art/workb10.jpg", description: "Acrylique sur papier\n70cm x 50cm" },
            ],
            selData: {},
            search: '',
            page: 1,
            colNumber: 4,
            rowCount: 4,
            currentArtUrl: '',
            currentDescription: '',
            openArtDlg: false
        }
    },
    created: function () {
    },
    methods: {
        itemCountInRow: function (row) {
            let retRow = this.datas.slice((row - 1) * this.colNumber, row * this.colNumber);
            if (retRow.length < this.colNumber) {
                const max = this.colNumber - retRow.length;
                for (let i = 0; i < max; i++) {
                    retRow.push({});
                }
            }
            return retRow;
        },
        showDetail(url, description) {
            this.currentArtUrl = url;
            this.currentDescription = description;
            this.openArtDlg = true;
        },
        execOpenArtDlg(data) {
            this.openArtDlg = data.openDialog;
        }
    }
};
</script>