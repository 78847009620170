<template>
    <v-card>
      <v-toolbar color="#ffffff">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
          icon
          dark
          @click="execEmit(false)"
        >
          <v-icon style="color:rgb(25, 25, 25)">mdi-close</v-icon>
        </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-layout justify-center>
        <v-container>
            <div class="d-flex justify-content-center">
              <img class="art-dialog-image" :src="r_fileUrl" />
            </div>
            <div class="d-flex align-items-center justify-content-center col">
              <div class="art-dialog-description">{{r_description}}</div>
            </div>
        </v-container>
      </v-layout>
    </v-card>
    
</template>
<script>
  import { ref, defineComponent } from 'vue'
  export default defineComponent({
    props: {
        fileUrl:{
            type:String
        },
        description:{
            type:String
        }
    },
    data(){
      return{
      }
    },
    emits: ['eventEmit'],
    setup(props, context) {
        //const open = ref(false);
      const r_fileUrl = ref(props.fileUrl);
      const r_description = ref(props.description);
      console.log(r_fileUrl);
      const execEmit = (isOpen) => {
        context.emit('eventEmit', { 'result': true, 'openDialog':isOpen})
      }
      return {
        r_fileUrl,
        r_description,
        execEmit,
      }
    },
    methods :{
    }
  })
  </script>