<template>
  <headerComp :mode="'art'"></headerComp>
  <div class="container-full">
    <div class="row">
      <div class="d-flex logo-art-container fade-in2">
        <div class="logo-art">
          Art
        </div>
      </div>
    </div>
    <div class="art-container fade-in">
      <div class="art-parent">
        <div class="art-list">
          <div class="d-flex justify-content-start align-items-center">
            <div class="art-list-img">
              <!-- Thumb -->
              <img class="img-art-thumb" src="/img/art/work11.jpg" />
            </div>
            <router-link to="/art01">
              <div class="d-flex">Série Washi (papier japonais)</div>
            </router-link>
          </div>
        </div>
        <div class="art-list-r">
          <div class="d-flex justify-content-end align-items-center">
            <router-link to="/art02">
              <div class="d-flex">Série Bleu</div>
            </router-link>
            <div class="art-list-img">
              <!-- Thumb -->
              <img class="img-art-thumb" src="/img/art/workb04.jpg" />
            </div>
          </div>

        </div>
      </div>
      <div class="art-parent-sp">
        <div class="art-list">
            <div class="art-list-img">
              <!-- Thumb -->
              <img class="img-art-thumb" src="/img/art/work11.jpg" />
            </div>
            <router-link to="/art01">
              <div class="d-flex">Série Washi (papier japonais)</div>
            </router-link>
        </div>
        <div class="art-list">
            <div class="art-list-img">
              <!-- Thumb -->
              <img class="img-art-thumb" src="/img/art/workb04.jpg" />
            </div>
            <router-link to="/art02">
              <div class="d-flex">Série Bleu</div>
            </router-link>

        </div>
      </div>
      <div class="art-list-c">
        <div class="col-md-6 d-flex justify-content-start art-list-description">
          Les éléments de la nature, la lumière, le son, le mouvement, l'énergie et l'aura qu'ils émettent sur David
          créent dans son imaginaire une image rémanente abstraite.<br /><br />
          Quand David se sent en lien avec ces éléments, il imagine des images ressemblant à des ombres, à la lumière, à
          des courants d'air transparents. Parfois leurs formes changent avec le temps, ou restent complètement
          inchangées.<br />
          Ces images rémanentes sont liées aux émotions de David. Il les projette dans l'instant sur papier ou sur
          toile.<br />
          David ne peint pas une forme, mais un espace, un mouvement constitudé d'impressions d'émotions.<br />
          David aime que son public interagisse avec ses peintures.<br /><br />
          "J'ai toujours l'impression d'avoir une image coincée derrière mes yeux et je ne peux m'empêcher de la
          projeter."<br /><br />
        </div>
      </div>
      <div class="spacer"></div>
    </div>
  </div>

  <div class="text-center">
    <v-dialog v-model="openArtDlg" persistent width="100%" height="100%">
      <artDialog :fileUrl="currentArtUrl" :description="currentDescription" @eventEmit="execOpenArtDlg">
      </artDialog>
    </v-dialog>
  </div>
</template>

<script>
  import HeaderComp from '../components/HeaderComp.vue';
  import ArtDialog from '../components/ArtDialog.vue';
  export default{
    components: {
      HeaderComp,
      ArtDialog
    },
    data() {
      return {
      }
    },
    created: function() {
    },
    methods: {
      
    }
  };
</script>