<template>
  <carousel ref="carousel" @init="handleInit" :breakpoints="breakpoints">
    <slide v-for="(r_item,index) in r_items" :key="index">
      <div class="carousel-img-container">
        <img class="carousel-img" :src="r_item" />
      </div>
    </slide>

    <template #addons>
      <Navigation />
    </template>
  </carousel>
  <div class="carousel-bt d-flex justify-content-center">
    <button @click="prev"><v-icon>mdi-arrow-left-bold</v-icon>Prev
    </button>
    <button @click="next">Next<v-icon>mdi-arrow-right-bold</v-icon></button>
  </div>
</template>
  
  <script>
  import 'vue3-carousel/dist/carousel.css'
  import '../assets/carousel.css'
  // eslint-disable-next-line no-unused-vars
  import { Carousel, Slide, Navigation} from 'vue3-carousel'
  import { ref, defineComponent } from 'vue'
  export default defineComponent({
    components: {
      Carousel,
      Slide
    },
    data() {
    },
    props: ['items'],
    setup(props) {
        const r_items = ref(props.items);
        return {r_items}
    },
    methods: {
      handleInit(){
        console.log('handleInit');
      },
      next() {
      this.$refs.carousel.next()
      },
      prev() {
        this.$refs.carousel.prev()
      },
    }
});
  </script>