<template>
  <v-app>
    <v-main>
      <router-view/>
      <div class="footer d-flex">
        <div class="footer-container">
            <div class="footer-text"></div>
            <div class="copy">&copy;2023 David TAKAHASHI</div>
        </div>
        <!--
        <div class="sns-logo">
          <a href="https://www.instagram.com/david.takahashi">
            <img src="img/Instagram_Glyph_Black.png" width="30" height="30">
          </a>
        </div>
        -->
      </div>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
